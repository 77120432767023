import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD1JW_5DBV7DBIaxq89rJcGTBolHZp-n8U',
  authDomain: 'kiddables-7f30d.firebaseapp.com',
  databaseURL: 'https://kiddables-7f30d.firebaseio.com',
  projectId: 'kiddables-7f30d',
  storageBucket: 'kiddables-7f30d.appspot.com',
  messagingSenderId: '80964771266',
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
