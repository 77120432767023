import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createUser } from '../../actions';
import * as firebase from 'firebase/app';
import { myFirebase } from '../../firebase/firebase';
import withFirebaseAuth from 'react-with-firebase-auth';
import googleLogo from '../../static/google.png';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './newAccount.scss';

const NewAccount = props => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const [missingFields, setMissingFields] = useState({});

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      email: !email,
      password: !password,
      confirmPassword: password !== confirmPassword
    };

    setMissingFields(errors);

    if (
      firstName &&
      lastName &&
      email &&
      password &&
      password === confirmPassword
    )
      dispatch(createUser(email, password, firstName, lastName));
  };

  const { signInWithGoogle } = props;

  if (isAuthenticated) {
    return <Redirect to="/mychildren" />;
  } else {
    return (
      <div className="new-account-container">
        <a href="/" className="back-link">
          <FontAwesomeIcon className="back-icon" icon={faChevronLeft} /> Back to
          kiddables
        </a>
        <div className="new-account-card">
          <h1 className="new-account-header">Create an account</h1>
          <label className="text-input-label">First Name:</label>
          <input
            id="firstName"
            label="First Name"
            name="firstName"
            className="text-input"
            onChange={e => setFirstName(e.target.value)}
          />
          {missingFields.firstName && (
            <p component="p" className={'error-text'}>
              First Name is required.
            </p>
          )}

          <label className="text-input-label">Last Name:</label>
          <input
            id="lastName"
            label="Last Name"
            name="lastName"
            className="text-input"
            onChange={e => setLastName(e.target.value)}
          />
          {missingFields.lastName && (
            <p component="p" className={'error-text'}>
              Last Name is required.
            </p>
          )}

          <label className="text-input-label">Email Address:</label>
          <input
            id="email"
            label="Email Address"
            name="email"
            className="text-input"
            onChange={e => setEmail(e.target.value)}
          />
          {missingFields.email && (
            <p component="p" className={'error-text'}>
              Email Address is required.
            </p>
          )}

          <label className="text-input-label">Password:</label>
          <input
            name="password"
            label="Password"
            type="password"
            id="password"
            className="text-input"
            onChange={e => setPassword(e.target.value)}
          />
          {missingFields.password && (
            <p component="p" className={'error-text'}>
              Password is required.
            </p>
          )}

          <label className="text-input-label">Confirm Password:</label>
          <input
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            className="text-input"
            onChange={e => setConfirmPassword(e.target.value)}
          />
          {missingFields.confirmPassword && (
            <p component="p" className={'error-text'}>
              Passwords must match.
            </p>
          )}

          <button
            type="button"
            className="btn btn-primary submit"
            onClick={handleSubmit}
          >
            Create Account
          </button>

          <div className="divider">
            <div className="divider-line" />
            <p className="divider-text">or</p>
            <div className="divider-line" />
          </div>
          <button
            type="button"
            className="btn btn-outline-dark sign-in-google"
            onClick={signInWithGoogle}
          >
            <div className="sign-in-google-content-container">
              <img
                className="google-logo"
                alt="Google logo"
                src={googleLogo}
              ></img>
              Sign up with Google Account
            </div>
          </button>

          <p className="already-have-account">
            Already have an account? <a href="/login">Sign in</a>
          </p>
        </div>
      </div>
    );
  }
};

const firebaseAppAuth = myFirebase.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(NewAccount);
