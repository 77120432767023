import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import JumpRopeImage from '../../../static/jump-rope-primary.png';
import './cta.scss';

const Cta = () => {
  return (
    <div className="feature-split-container cta-container">
      <div className="vertical">
        <h1 className="feature-header">Get started today</h1>
        <p className="feature-description">
          Sign up for free and start your child's journal to help remember the
          little years!
        </p>
        <a href="https://apps.apple.com/us/app/kiddables/id1510791154">
          <span className="get-started-link">
            Get Started <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </a>
      </div>
      <div className="icon-container">
        <img className="icon" alt="girl jumping rope" src={JumpRopeImage} />
      </div>
    </div>
  );
};

export default Cta;
