import React from 'react';
import GirlBalloon from '../../../static/girl-balloon-primary.png';
import './privacy.scss';

const Privacy = () => {
  return (
    <div className="feature-split-container privacy-container">
      <div className="icon-container">
        <img
          className="icon"
          src={GirlBalloon}
          alt="outline of girl holding a balloon"
        />
      </div>
      <div className="vertical">
        <h1 className="feature-header">Safe and secure</h1>
        <p className="feature-description">
          Everything you save to kiddables is owned by you, not advertisers.
          Rest easy knowing that your child's words are private, ad-free, and
          securely archived. Export full transcripts for free at any time.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
