import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import withFirebaseAuth from 'react-with-firebase-auth';
import { myFirebase } from '../../firebase/firebase';
import * as actions from '../../actions';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DatePicker from 'react-widgets/lib/DatePicker';
import { Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCloudShowersHeavy,
} from '@fortawesome/free-solid-svg-icons';

import 'react-widgets/dist/css/react-widgets.css';
import './addChildModal.scss';

moment.locale('en');
momentLocalizer();

const AddChildModal = (props) => {
  const { onClose, selectedChild } = props;

  const [name, setName] = useState(selectedChild ? selectedChild.name : '');

  const [birthdate, setBirthdate] = useState(
    selectedChild ? new Date(selectedChild.birthdate) : new Date()
  );

  const [avatar, setAvatar] = useState(selectedChild?.avatar ?? 'boy');
  const [color, setColor] = useState(selectedChild?.color ?? '0a84ff');

  const [submitted, setSubmitted] = useState();
  const dispatch = useDispatch();

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const submit = () => {
    dispatch(
      actions.addChild({
        name,
        birthdate: moment(birthdate).toISOString(),
        id: uuidv4(),
        avatar,
        color,
      })
    );
    dispatch(actions.fetchUserData());

    onClose();
  };

  const edit = () => {
    dispatch(
      actions.editChild(selectedChild.id, {
        name,
        birthdate: moment(birthdate).toISOString(),
        avatar,
        color,
      })
    );
    dispatch(actions.fetchUserData());

    onClose();
  };

  const avatarMap = {
    boy: require('../../static/boy.png'),
    girl: require('../../static/girl.png'),
  };

  const colors = ['0a84ff', '30d158', 'ff9f0a', '5e5ce6', 'ff375f', 'bf5af2'];
  return (
    <Fragment>
      <div className="modal-background fade-in-bg" onClick={onClose} />
      <div className="add-child-modal-container fade-in">
        <div className="add-child-modal">
          <div className="header">
            {!selectedChild && <p>Add a kid to your kiddables account:</p>}
            {selectedChild && <p>Edit details about your kid:</p>}
          </div>
          <div className="input-container">
            <label htmlFor="child-input">Name:</label>
            <Input
              value={name}
              onChange={(event) => setName(event.target.value)}
              id="child-input"
              className="name-input"
              type="text"
            />
          </div>
          {submitted && !name && <p className="error-text">Name is required</p>}

          <div className="input-container">
            <label htmlFor="child-input">Birthdate:</label>
            <DatePicker
              defaultValue={birthdate}
              className="birthdate-input"
              onChange={(e) => setBirthdate(e)}
            />
          </div>
          {submitted && !birthdate && (
            <p className="error-text">Birthdate is required</p>
          )}

          <div className="input-container">
            <label htmlFor="child-input">Avatar:</label>
            <div className="avatar-button-container">
              <button
                className={`avatar-button ${
                  avatar === 'boy' ? 'selected' : ''
                }`}
                onClick={() => setAvatar('boy')}
              >
                <img
                  className="avatar-button-image"
                  alt="avatar"
                  src={avatarMap['boy']}
                />
              </button>
              <button
                className={`avatar-button ${
                  avatar === 'girl' ? 'selected' : ''
                }`}
                onClick={() => setAvatar('girl')}
              >
                <img
                  className="avatar-button-image"
                  alt="avatar"
                  src={avatarMap['girl']}
                />
              </button>
            </div>
          </div>

          <div className="input-container">
            <label htmlFor="child-input">Color:</label>
            <div className="color-button-container">
              {colors.map((c) => (
                <button
                  className={`color-button ${color === c ? 'selected' : ''}`}
                  style={{ background: `#${c}` }}
                  onClick={() => setColor(c)}
                />
              ))}
            </div>
          </div>

          <div className="button-container">
            {selectedChild && (
              <button
                title="Delete this kid"
                className="btn btn-outline-primary delete-button"
                onClick={() => {
                  dispatch(
                    actions.setModal('Confirmation', {
                      onCancel: onClose,
                      onConfirm: () => {
                        dispatch(actions.removeChild(selectedChild.id));
                        dispatch(actions.fetchUserData());
                        onClose();
                      },
                      header: 'Are you sure?',
                      description:
                        'Proceeding will remove this kid and all associated entries from your account.  This action cannot be undone.',
                    })
                  );
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            <button className="btn btn-outline-primary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setSubmitted(true);
                if (name && birthdate) {
                  if (selectedChild) {
                    edit();
                  } else {
                    submit();
                  }
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const firebaseAppAuth = myFirebase.auth();

export default withFirebaseAuth({
  providers: null,
  firebaseAppAuth,
})(AddChildModal);
