import { FETCH_RESPONSE_DATA } from '../actions/types';
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_RESPONSE_DATA:
      return {
        ...state,
        responseData: action.responseData
      };
    default:
      return state;
  }
};
