import { SET_MODAL, CLEAR_MODAL } from '../actions/types';
export default (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case CLEAR_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    default:
      return state;
  }
};
