import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import Home from './components/Home';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import MyChildren from './components/MyChildren';
import NewAccount from './components/NewAccount';
import ResetPassword from './components/ResetPassword';
import Privacy from './components/Privacy';
import TermsAndConditions from './components/Privacy/termsAndConditions';
import Help from './components/Privacy/help';

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isVerifying = useSelector((state) => state.auth.isVerifying);

  return (
    <Switch>
      <UnprotectedRoute
        exact
        path="/"
        redirectTo="/home"
        component={LandingPage}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <UnprotectedRoute
        exact
        path="/newaccount"
        redirectTo="/mychildren"
        component={NewAccount}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/home"
        component={Home}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/mychildren"
        component={MyChildren}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <UnprotectedRoute
        exact
        path="/login"
        redirectTo="/home"
        component={Login}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <Route path="/resetpassword" component={ResetPassword} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/termsandconditions" component={TermsAndConditions} />
      <Route path="/help" component={Help} />
    </Switch>
  );
};

export default App;
