import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import * as actions from '../../../actions';
import { getQuestionById } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import './question.scss';

const Question = (props) => {
  const { entries, user, index } = props;

  const dispatch = useDispatch();
  const getChildById = (id) => {
    if (user && Object.keys(user)?.length > 0) {
      const key = Object.keys(user)?.find((key) => user[key].id === id);
      return user[key];
    }

    return null;
  };
  const avatarMap = {
    boy: require('../../../static/boy.png'),
    girl: require('../../../static/girl.png'),
  };

  const entry = entries[0];
  const question = getQuestionById(entry.questionId);

  const mapped = entries.map((e) => {
    const child = getChildById(e.id);
    const childAge =
      child &&
      Math.floor(
        moment.duration(moment(e.date).diff(moment(child.birthdate))).asYears()
      );

    const ageText = ` age ${childAge}`;
    const showAge =
      moment.duration(moment().diff(moment(e.date))).asYears() > 1;

    return {
      date: e.date,
      child: child?.name,
      childAge,
      parentNotes: e.parentNotes,
      childResponse: e.childResponse,
      color: child?.color,
      avatar: child?.avatar,
      ageText,
      showAge,
    };
  });

  const sorted =
    mapped && mapped.sort((a, b) => (moment(a.date) > moment(b.date) ? -1 : 1));
  return (
    <div
      style={{ animationDelay: `${index / 20}s`, opacity: 0 }}
      className="question-card-container fade-in"
    >
      <div className="response-container">
        <div className="header">
          <div className="title-container">
            <p>
              <strong>
                Question for {moment(entry.date).format('MMMM D')}
              </strong>
            </p>
          </div>
          <button
            className="btn btn-outine-primary edit-button"
            onClick={() => {
              dispatch(
                actions.setModal('Question', {
                  onClose: () => dispatch(actions.clearModal()),
                  date: entry.date,
                  child: entry.child,
                })
              );
            }}
          >
            <FontAwesomeIcon className="menu-icon" icon={faEllipsisH} />
          </button>
        </div>

        <span className="question-container">
          <div className="icon-container">
            <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
          </div>
          <p>{question.displayText}</p>
        </span>

        {sorted &&
          sorted.map((e) => {
            return (
              <div className="child-response-container">
                <p className="timestamp">
                  {moment.utc(e.date).local().fromNow()}
                </p>
                <div className="response-quote-container">
                  <div
                    style={{ background: `#${e.color}` }}
                    className="response-quote"
                  >
                    {e.childResponse}
                  </div>
                </div>
                <div
                  className="callout"
                  style={{ borderTopColor: `#${e.color}` }}
                />
                <p className="author-name">
                  <img
                    className="avatar"
                    alt="avatar"
                    src={avatarMap[e.avatar]}
                  />
                  {`${e.child}${e.showChildAge ? e.childAge : ''}`}{' '}
                </p>
                {e.parentNotes && (
                  <div className="parent-comments-container">
                    <p className="parent-comments">{`Comments: ${e.parentNotes}`}</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Question;
