import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import NavbarLV from './navbarLV';
import NavbarSV from './navbarSV';
import NewQuestionModal from './newQuestionModal';
import ConfirmationModal from '../Confirmation';
import AddChildModal from '../MyChildren/addChildModal';

import './navbar.scss';

const Navbar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const modalType = useSelector(state => state.app.modalType);
  const modalProps = useSelector(state => state.app.modalProps);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <Fragment>
      {width <= 576 && <NavbarSV />}
      {width > 576 && <NavbarLV />}

      {modalType === 'Question' && <NewQuestionModal {...modalProps} />}
      {modalType === 'Add Child' && <AddChildModal {...modalProps} />}
      {modalType === 'Confirmation' && <ConfirmationModal {...modalProps} />}
    </Fragment>
  );
};

export default Navbar;
