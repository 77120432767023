import React from 'react';
import logo from '../../static/full-logo-white.png';
import { logoutUser } from '../../actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faComment,
  faChild,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

import './menuSV.scss';

const MenuSV = props => {
  const { onClose } = props;

  const dispatch = useDispatch();

  return (
    <div className="sv-menu-container">
      <button className="close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <div className="icon-container">
        <div className="icon-container">
          <img className="icon" src={logo} alt="cartoon kids talking" />
        </div>
        <p className="logo-text">kiddables</p>
      </div>
      <div className="links-container">
        <ul>
          <a href="/home" className="list-item-container">
            <li className="list-item">
              <FontAwesomeIcon icon={faComment} />
              <span className="item-text">My Journal</span>
              <FontAwesomeIcon
                className="chevron-right"
                icon={faChevronRight}
              />
            </li>
          </a>
          <a href="/mychildren" className="list-item-container">
            <li className="list-item last">
              <FontAwesomeIcon icon={faChild} />
              <span className="item-text">My Children</span>
              <FontAwesomeIcon
                className="chevron-right"
                icon={faChevronRight}
              />
            </li>
          </a>
        </ul>
      </div>
      <button
        className="btn btn-outline-dark sign-out"
        onClick={() => dispatch(logoutUser())}
      >
        Sign Out
      </button>
    </div>
  );
};

export default MenuSV;
