import { FETCH_USER_DATA } from '../actions/types';
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      return {
        ...state,
        userData: action.userData
      };
    default:
      return state;
  }
};
