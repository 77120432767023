export const lockBackgroundForModal = () => {
  if (document && document.body) {
    const orig = document.body.className;
    if (!orig.includes('modal-open')) {
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }
  }
};
export const unlockBackgroundForModal = () => {
  if (document && document.body) {
    document.body.className = document.body.className.replace(
      / ?modal-open/,
      ''
    );
  }
};
