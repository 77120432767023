import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetPassword } from '../../actions';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './resetPassword.scss';

const ResetPassword = () => {
  const [email, setEmail] = useState();
  const [isRequested, setIsRequested] = useState();
  const [isCancelled, setIsCancelled] = useState();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(resetPassword(email));
    setIsRequested(true);
  };

  if (isRequested) {
    return (
      <div className="reset-password-container">
        <a href="/" className="back-link">
          <FontAwesomeIcon className="back-icon" icon={faChevronLeft} /> Back to
          kiddables
        </a>
        <div className="reset-password-card">
          <div className="reset-sent">
            Password reset email sent to {email}. Please check your email.
          </div>
          <a href="/">Back to homepage</a>
        </div>
      </div>
    );
  }

  if (isAuthenticated || isCancelled) {
    return <Redirect to="/home" />;
  } else {
    return (
      <div className="reset-password-container">
        <a href="/" className="back-link">
          <FontAwesomeIcon className="back-icon" icon={faChevronLeft} /> Back to
          kiddables
        </a>
        <div className="reset-password-card">
          <h1 className="reset-password-header">Reset your password</h1>
          <label className="text-input-label">Email Address:</label>
          <input
            id="email"
            label="Email Address"
            name="email"
            className="text-input"
            onChange={e => setEmail(e.target.value)}
          />

          <button
            type="button"
            className="btn btn-primary submit"
            onClick={handleSubmit}
          >
            Continue
          </button>
          <button
            type="button"
            className="btn btn-outline-primary cancel"
            onClick={() => setIsCancelled(true)}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
