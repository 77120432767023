import React from 'react';

import './privacy.scss';

const Help = () => {
  return (
    <div className="privacy-policy-container">
      <strong>Need Help?</strong>{' '}
      <p>
        We're happy to assist! Please reach out to us directly via email at
        <a href="mailto:hello@kiddables.com">hello@kiddables.com</a>
      </p>
    </div>
  );
};

export default Help;
