import React, { useEffect, Fragment } from 'react';
import Navbar from '../Navbar';
import { useDispatch, useSelector } from 'react-redux';
import withFirebaseAuth from 'react-with-firebase-auth';
import { myFirebase } from '../../firebase/firebase';
import * as actions from '../../actions';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faArrowRight,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';

import './myChildren.scss';

const MyChildren = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.userData);
  const responses = useSelector((state) => state.data.responseData);

  const avatarMap = {
    boy: require('../../static/boy.png'),
    girl: require('../../static/girl.png'),
  };

  const childResponses =
    responses &&
    Object.keys(responses).map((key) => {
      return { name: responses[key].child };
    });

  useEffect(() => {
    dispatch(actions.fetchUserData());
    dispatch(actions.fetchResponseData());
  }, [props, dispatch]);

  const hasChildren = data && Object.keys(data).length > 0;

  return (
    <Fragment>
      <Navbar />
      <div className="my-children-root">
        <div className="my-children-container">
          <h1 className="welcome-header">
            Hi, {props.user && props.user.displayName.split(' ')[0]}
          </h1>
          <p className="description">Tell us a little bit about your family:</p>

          <div className="children-list">
            {hasChildren && (
              <Fragment>
                {Object.keys(data).map((key) => {
                  const count =
                    childResponses &&
                    childResponses.filter((r) => r.name === data[key].name)
                      .length;

                  return (
                    <div className="child-container">
                      <div className="edit-button-container">
                        <button
                          className="btn btn-outine-primary edit-button"
                          onClick={() => {
                            dispatch(
                              actions.setModal('Add Child', {
                                selectedChild: Object.assign(data[key], {
                                  id: key,
                                }),
                                onClose: () => dispatch(actions.clearModal()),
                              })
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            className="menu-icon"
                            icon={faEllipsisH}
                          />
                        </button>
                      </div>
                      <div className="child-details-container">
                        <img
                          className="child-icon"
                          alt="avatar"
                          src={avatarMap[data[key].avatar]}
                        />
                        <div className="child-details">
                          <p className="child-name">{data[key].name}</p>
                          <p className="child-age">
                            Born{' '}
                            {moment(data[key].birthdate).format('MMMM D, YYYY')}
                          </p>
                          <p className="child-age">
                            Age:{' '}
                            {moment
                              .duration(
                                moment().diff(moment(data[key].birthdate))
                              )
                              .humanize()}
                          </p>
                          <p className="child-entries">
                            {count === 1
                              ? '1 journal entry'
                              : count > 1
                              ? `${count} journal entries`
                              : 'No journal entries yet'}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            )}
          </div>

          <button
            className="btn btn-primary add-child-button"
            onClick={() => {
              dispatch(
                actions.setModal('Add Child', {
                  selectedChild: null,
                  onClose: () => dispatch(actions.clearModal()),
                })
              );
            }}
          >
            {hasChildren ? 'Add another child' : 'Add child'}
          </button>

          <div className="view-quotes-container">
            <a className="view-quotes" href="/home">
              View my journal
              <FontAwesomeIcon className="forward-icon" icon={faArrowRight} />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const firebaseAppAuth = myFirebase.auth();

export default withFirebaseAuth({
  providers: null,
  firebaseAppAuth,
})(MyChildren);
