import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Description from './cards/description';
import Cta from './cards/cta';
import HowItWorks from './cards/howItWorks';
import Privacy from './cards/privacy';
import logo from '../../static/full-logo-white.png';
import AppStoreIcon from '../../static/app-store.png';
import GooglePlayIcon from '../../static/google-play.png';
import screenshot from '../../static/screenshot.png';
import './landingPage.scss';

const LandingPage = () => {
  return (
    <div className="landing-page-container">
      <section className="hero-section">
        {/* <a href="/login" className="sign-in">
          <button
            className="btn btn-outline-primary sign-in-button"
            type="button"
          >
            Sign In
          </button>
        </a> */}
        <div className="hero-heading-container">
          <div className="logo-container">
            <img alt="kiddables logo" className="logo-image" src={logo} />
          </div>
          <h1 className="hero-heading">Questions for kids</h1>
          <h1 className="hero-heading">Tracked day by day</h1>
        </div>

        <div className="horizontal">
          <div className="store-icon-container apple">
            <a href="https://apps.apple.com/us/app/kiddables/id1510791154">
              <img
                className="app-store-icon"
                alt="Download on the App Store"
                src={AppStoreIcon}
              />
            </a>
          </div>
          {/* <div className="store-icon-container">
            <img
              className="app-store-icon"
              alt="Get it on Google Play"
              src={GooglePlayIcon}
            />
          </div> */}
        </div>

        <a
          href="https://apps.apple.com/us/app/kiddables/id1510791154"
          className="create-account"
        >
          <button
            className="btn btn-outline-primary create-account-button"
            type="button"
          >
            Get Started with kiddables
          </button>
        </a>

        {/* <a className="already-have-account" href="/login">
          Already have an account? <strong>Sign in</strong>
        </a> */}
        <a href="#content" className="navigate-down-link">
          <button className="btn btn-outline-secondary navigate-down">
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        </a>
      </section>
      <header id="content" className="sticky-header">
        <h1 className="logo-text">kiddables</h1>
        <a href="https://apps.apple.com/us/app/kiddables/id1510791154">
          <button
            className="btn btn-outline-secondary join-button"
            type="button"
          >
            Download
          </button>
        </a>
      </header>
      <section id="organize" className="feature-card">
        <Description />
      </section>
      <section id="privacy" className="feature-card">
        <Privacy />
      </section>
      <section id="howItWorks" className="feature-card no-background">
        <HowItWorks />
      </section>
      <section id="cta" className="feature-card">
        <Cta />
      </section>
      <section id="footer" className=" no-background">
        <p className="copyright">{`© ${moment().format(
          'YYYY'
        )} - Tim Oehler `}</p>
      </section>
    </div>
  );
};

export default LandingPage;
