import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../Navbar';
import * as actions from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import QuestionCard from './Cards/Question';
import Masonry from 'react-masonry-css';
import { groupBy } from './utils';
import moment from 'moment';

import './home.scss';

const Home = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.responseData);
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    dispatch(actions.fetchResponseData());
    dispatch(actions.fetchUserData());
  }, []);

  const breakpointCols = {
    default: 3,
    1110: 3,
    1100: 2,
    700: 1,
  };

  const mapped =
    data &&
    Object.keys(data).map((key) => {
      return {
        child: data[key].child,
        childResponse: data[key].childResponse,
        date: data[key].date,
        shortDate: moment(data[key].date).format('MM/DD'),
        parentNotes: data[key].parentNotes,
        questionId: data[key].questionId,
        key: data[key].date,
        id: data[key].id,
        type: data[key].type,
      };
    });

  const grouped = mapped && groupBy(mapped, 'shortDate', 'type');

  const sorted =
    grouped &&
    Object.values(grouped).sort((a, b) =>
      moment(a[0].date) > moment(b[0].date) ? -1 : 1
    );

  return (
    <Fragment>
      <Navbar />

      <div className="home-container">
        {user === null && (
          <div className="zero-state">
            <p>
              Welcome to Kid's Quotes. To start, you'll need to add children to
              your account.
            </p>
            <a href="mychildren">
              Configure children <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </div>
        )}
        {user && data === null && (
          <div className="zero-state">
            <p>It looks like you don't have any entries yet. Let's fix that.</p>
            <button
              onClick={() => dispatch(actions.setModal('Question', null))}
              className="btn btn-primary"
            >
              Add Entry
            </button>
          </div>
        )}
        <Masonry
          breakpointCols={breakpointCols}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {sorted &&
            sorted.map((g, i) => {
              return <QuestionCard entries={g} user={user} index={i} />;
            })}
        </Masonry>
      </div>
    </Fragment>
  );
};

export default Home;
