import React, { useState, useEffect, Fragment } from 'react';
import { clearModal } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { getQuestionId, getQuestion } from '../Home/utils';
import { Form, TextArea } from 'semantic-ui-react';
import DropdownList from 'react-widgets/lib/DropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './newQuestionModal.scss';

const NewQuestionModal = (props) => {
  const dispatch = useDispatch();
  const [childResponse, setChildResponse] = useState('');
  const [parentNotes, setParentNotes] = useState('');
  const [date, setDate] = useState(moment(props && props.date));
  const [selectedChild, setSelectedChild] = useState(props && props.child);
  const [submitted, setSubmitted] = useState();
  const [responseKey, setResponseKey] = useState();
  const userData = useSelector((state) => state.user.userData);
  const responseData = useSelector((state) => state.data.responseData);

  useEffect(() => {
    const initialData = getResponse(responseData, selectedChild, date);
    setResponseKey(
      responseData &&
        Object.keys(responseData).find((k) => responseData[k] === initialData)
    );
    setChildResponse(initialData ? initialData.childResponse : '');
    setParentNotes(initialData ? initialData.parentNotes : '');
  }, [selectedChild, date]);

  const submit = (event) => {
    setSubmitted(true);
    if (!childResponse || !selectedChild) {
      return;
    }

    const initial = getResponse(responseData, selectedChild, date);

    if (!initial) {
      const id = Object.values(userData).find((v) => v.name === selectedChild)
        .id;
      dispatch(
        actions.addUserResponse({
          id,
          type: 'Question',
          childResponse,
          parentNotes,
          questionId: getQuestionId(date),
          date: moment(date).toISOString(),
          child: selectedChild,
        })
      );
    } else {
      const id = Object.keys(responseData).find(
        (k) => responseData[k] === initial
      );
      dispatch(
        actions.editUserResponse(id, {
          childResponse,
          parentNotes,
          questionId: getQuestionId(date),
          date: moment(date).toISOString(),
          child: selectedChild,
        })
      );
    }
    dispatch(actions.fetchResponseData());
    dispatch(actions.clearModal());
    setChildResponse('');
  };

  const children =
    userData && Object.keys(userData).map((key) => userData[key].name);

  if (children?.length === 1 && !selectedChild) {
    setSelectedChild(children[0]);
  }

  const todaysQuestion = getQuestion(date);

  return (
    <Fragment>
      <div
        className="modal-background fade-in-bg"
        onClick={() => dispatch(clearModal())}
      />
      <div className="modal-container fade-in">
        <div className="modal">
          <div className="new-question-modal-container">
            <div className="title-container">
              <h1 className="title">Today's Question</h1>
              <div className="header">
                <button
                  className="calendar-button"
                  onClick={() => setDate(moment(date.subtract(1, 'day')))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <h1>{date.format('MMMM D, YYYY')}</h1>
                <button
                  className="calendar-button"
                  onClick={() => setDate(moment(date.add(1, 'day')))}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>

            <div className="question-container">
              <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
              <label className="question" htmlFor="question-input">
                {todaysQuestion.displayText}
              </label>
            </div>
            <DropdownList
              className="child-combo"
              placeholder="-- Select Child --"
              data={children}
              value={selectedChild}
              onChange={(e) => {
                setSelectedChild(e);
              }}
            />

            {submitted && !selectedChild && (
              <p className="error-text">Child is required</p>
            )}

            <Form>
              <p
                className={`description-text ${
                  !selectedChild ? 'disabled' : ''
                }`}
              >
                {selectedChild ? `${selectedChild}'s response:` : 'Response'}
              </p>

              <TextArea
                disabled={!selectedChild}
                value={childResponse}
                onChange={(e) => setChildResponse(e.target.value)}
                className="question-input"
                id="question-input"
                rows={4}
              />

              <p
                className={`notes-text ${!selectedChild ? 'disabled' : ''}`}
              >{`Parent notes:`}</p>

              <TextArea
                disabled={!selectedChild}
                value={parentNotes}
                onChange={(e) => setParentNotes(e.target.value)}
                className="notes-input"
                id="notes-input"
                rows={4}
              />
            </Form>

            {submitted && !childResponse && (
              <p className="error-text">Text is required</p>
            )}

            <div className="button-container">
              {getResponse(responseData, selectedChild, date) && (
                <button
                  title="Delete this response"
                  className="btn btn-outline-primary delete-button"
                  onClick={() => {
                    dispatch(
                      actions.setModal('Confirmation', {
                        onCancel: () => dispatch(actions.clearModal()),
                        onConfirm: () => {
                          dispatch(actions.removeUserResponse(responseKey));
                          dispatch(actions.fetchResponseData());
                          dispatch(actions.clearModal());
                        },
                        header: 'Are you sure?',
                        description:
                          'Proceeding will delete this response.  This action cannot be undone.',
                      })
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              )}
              <button
                className="btn btn-outline-primary"
                onClick={() => dispatch(actions.clearModal())}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={() => submit()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const getResponse = (responseData, selectedChild, date) => {
  return (
    responseData &&
    Object.values(responseData).find(
      (d) =>
        d.child === selectedChild && moment(d.date).isSame(moment(date), 'day')
    )
  );
};

export default NewQuestionModal;
