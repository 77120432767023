import React, { useState } from 'react';
import Logo from '../../static/logo.png';
import { logoutUser, setModal } from '../../actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import SmallViewMenu from './menuSV';

import './navbarSV.scss';

const NavbarSV = () => {
  const [settingsMenuOpen, setSettingsMenuOpen] = useState();
  const dispatch = useDispatch();

  return (
    <nav className="navbar-sv-container">
      <button
        onClick={() => setSettingsMenuOpen(true)}
        className="hamburger-button"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      <button
        onClick={() =>
          window.scrollTo({
            top: -50,
            left: 0,
            behavior: 'smooth',
          })
        }
        className="logo-button"
      >
        kiddables
      </button>

      <button
        className="btn btn-outline-primary add-entry"
        onClick={() => {
          dispatch(setModal('Question', null));
        }}
      >
        <span>+</span>
      </button>

      {settingsMenuOpen && (
        <div
          className="settings-menu-background"
          onClick={() => setSettingsMenuOpen(false)}
        >
          <div className="settings-menu">
            <ul>
              <a href="/home">
                <div className="menu-item-container">
                  <li>Journal</li>
                </div>
              </a>
              <a href="/mychildren">
                <div className="menu-item-container">
                  <li>Children</li>
                </div>
              </a>

              <div className="separator" />
              <a href="/#logout" onClick={() => dispatch(logoutUser())}>
                <div className="menu-item-container">
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                    </span>
                  </li>
                </div>
              </a>
            </ul>
          </div>
        </div>
      )}

      {settingsMenuOpen && (
        <SmallViewMenu onClose={() => setSettingsMenuOpen(false)} />
      )}
    </nav>
  );
};

export default NavbarSV;
