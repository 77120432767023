import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faPencilAlt,
  faListAlt,
  faCalendarAlt,
  faSmileBeam,
} from '@fortawesome/free-solid-svg-icons';
import screenshot from '../../../static/screenshot.png';

import './howItWorks.scss';

const HowItWorks = () => {
  return (
    <div className="vertical how-it-works-container">
      <h1 className="feature-header">How it works:</h1>

      <div className="horizontal">
        <div className="vertical">
          <ul>
            <li className="feature-description">
              <div className="bullet-container">
                <FontAwesomeIcon
                  className="bullet-icon"
                  icon={faQuestionCircle}
                />
                <span>Each day, kiddables will provide a daily question.</span>
              </div>
            </li>
            <li className="feature-description">
              <div className="bullet-container">
                <FontAwesomeIcon className="bullet-icon" icon={faPencilAlt} />
                <span>Ask your kid and jot down what they say.</span>
              </div>
            </li>
            <li className="feature-description">
              <div className="bullet-container">
                <FontAwesomeIcon className="bullet-icon" icon={faListAlt} />
                <span>
                  Browse through previous responses and compare responses to the
                  same questions over the years.
                </span>
              </div>
            </li>
            <li className="feature-description">
              <div className="bullet-container">
                <FontAwesomeIcon className="bullet-icon" icon={faCalendarAlt} />
                <span>
                  No worries if you miss a day, you can always go back and add
                  responses later!
                </span>
              </div>
            </li>
            <li className="feature-description">
              <div className="bullet-container">
                <FontAwesomeIcon className="bullet-icon" icon={faSmileBeam} />
                <span>
                  kiddables takes care of everything for you! If you ever want
                  to leave, you can always download all of your data.
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
