import moment from 'moment';
const questions = require('../../static/questions.json');
const FEB_28 = 59;

export const getQuestionId = (date) =>
  !moment(date).isLeapYear() && moment(date).dayOfYear() > FEB_28
    ? moment(date).dayOfYear() + 1
    : moment(date).dayOfYear();

export const mapResponsesForDate = (data, date) => {
  return (
    data &&
    Object.keys(data)
      .filter((key) =>
        moment.utc(data[key].date).local().isSame(moment(date), 'day')
      )
      .map((key) => {
        return {
          key,
          childResponse: data[key].childResponse,
          parentNotes: data[key].parentNotes,
          date: data[key].date,
          child: data[key].child,
        };
      })
  );
};

export const getQuestion = (date) => {
  return questions.find((q) => q.id === getQuestionId(date));
};

export const getQuestionById = (id) => {
  return questions.find((q) => q.id === id);
};

export const groupBy = function (xs, key1, key2) {
  return xs.reduce(function (rv, x) {
    const key = x[key1] + '|' + x[key2];
    (rv[key] = rv[key] || []).push(x);
    return rv;
  }, {});
};
