import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUser } from '../../actions';
import * as firebase from 'firebase/app';
import { myFirebase } from '../../firebase/firebase';
import withFirebaseAuth from 'react-with-firebase-auth';
import googleLogo from '../../static/google.png';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './login.scss';

const Login = props => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const loginError = useSelector(state => state.auth.loginError);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(loginUser(email, password));
  };

  const { signInWithGoogle } = props;

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  } else {
    return (
      <div className="login-container">
        <a href="/" className="back-link">
          <FontAwesomeIcon className="back-icon" icon={faChevronLeft} /> Back to
          kiddables
        </a>
        <div className="login-card">
          <h1 className="sign-in-header">Sign in to your kiddables account</h1>
          <label className="text-input-label">Email Address:</label>
          <input
            id="email"
            label="Email Address"
            name="email"
            className="text-input"
            onChange={e => setEmail(e.target.value)}
          />
          <label className="text-input-label">Password:</label>

          <input
            name="password"
            label="Password"
            type="password"
            id="password"
            className="text-input"
            onChange={e => setPassword(e.target.value)}
          />
          {loginError && (
            <p component="p" className={'error-text'}>
              Incorrect email or password.
            </p>
          )}
          <button
            type="button"
            className="btn btn-primary submit"
            onClick={handleSubmit}
          >
            Sign In
          </button>
          <p className="forgot-password">
            Forgot your password? <a href="/resetpassword">Reset it</a>
          </p>

          <div className="divider">
            <div className="divider-line" />
            <p className="divider-text">or</p>
            <div className="divider-line" />
          </div>
          <button
            type="button"
            className="btn btn-outline-dark sign-in-google"
            onClick={signInWithGoogle}
          >
            <div className="sign-in-google-content-container">
              <img
                className="google-logo"
                alt="Google logo"
                src={googleLogo}
              ></img>
              Sign in with Google
            </div>
          </button>

          <p className="no-account">
            Don't have an account? <a href="/newaccount">Create one</a>
          </p>
        </div>
      </div>
    );
  }
};

const firebaseAppAuth = myFirebase.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(Login);
