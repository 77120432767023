import React, { Fragment } from 'react';
import './confirmation.scss';

const Confirmation = props => {
  const { header, description, onCancel, onConfirm } = props;
  return (
    <Fragment>
      <div className="modal-background fade-in-bg" />
      <div className="add-child-modal-container fade-in">
        <div className="add-child-modal">
          <h1 className="header">{header}</h1>
          <p className="description">{description}</p>
          <div className="button-container">
            <button
              className="btn btn-outline-primary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => onConfirm()}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Confirmation;
