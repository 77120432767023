import React from 'react';
import chatImage from '../../../static/chat-bubbles-primary.png';
import kidsImage from '../../../static/boy-girl-primary.png';
import './description.scss';

const Description = () => {
  return (
    <div className="feature-split-container description-container">
      <div className="vertical">
        <h1 className="feature-header">Save their words</h1>
        <p className="feature-description">
          kiddables provides a daily question and saves your child's answer. We
          provide an easy-to-use app to view and search your child's responses.
          You can easily compare responses to previous years, too! Best of all,
          the app is 100% free to use.
        </p>
      </div>
      <div className="icon-container">
        <div className="icon-upper-container">
          <img className="icon" src={chatImage} alt="cartoon kids talking" />
        </div>
        <div className="icon-lower-container">
          <img className="icon" src={kidsImage} alt="cartoon kids talking" />
        </div>
      </div>
    </div>
  );
};

export default Description;
