import React, { useState } from 'react';
import Logo from '../../static/full-logo-primary.png';
import { logoutUser, setModal } from '../../actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import './navbarLV.scss';

const NavbarLV = () => {
  const [settingsMenuOpen, setSettingsMenuOpen] = useState();
  const dispatch = useDispatch();

  return (
    <nav className="navbar-lv-container">
      <a href="/" className="logo-container">
        <img className="logo" src={Logo} alt="logo" />
      </a>

      <ul className="navbar-links">
        <li
          className={`list-item ${window.location.href.includes('/home') &&
            'selected'}`}
        >
          <a href="/home">journal</a>
        </li>
        <li
          className={`list-item ${window.location.href.includes(
            '/mychildren'
          ) && 'selected'}`}
        >
          {' '}
          <a href="/mychildren">children</a>
        </li>
      </ul>
      <button
        onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}
        className="settings-button"
      >
        <FontAwesomeIcon icon={faCog} />
      </button>

      <button
        className="btn btn-primary add-entry"
        onClick={() => {
          dispatch(setModal('Question', null));
        }}
      >
        Add Entry
      </button>

      {settingsMenuOpen && (
        <div
          className="settings-menu-background"
          onClick={() => setSettingsMenuOpen(false)}
        >
          <div className="settings-menu">
            <ul>
              <a href="/home">
                <div className="menu-item-container">
                  <li>Journal</li>
                </div>
              </a>
              <a href="/mychildren">
                <div className="menu-item-container">
                  <li>Children</li>
                </div>
              </a>

              <div className="separator" />
              <a href="/#logout" onClick={() => dispatch(logoutUser())}>
                <div className="menu-item-container">
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                    </span>
                  </li>
                </div>
              </a>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarLV;
