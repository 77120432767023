import * as firebase from 'firebase';
import {
  FETCH_RESPONSE_DATA,
  FETCH_USER_DATA,
  SET_MODAL,
  CLEAR_MODAL
} from './types';
import {
  lockBackgroundForModal,
  unlockBackgroundForModal
} from '../components/common';

export const setModal = (modalType, modalProps) => async dispatch => {
  lockBackgroundForModal();
  dispatch({
    type: SET_MODAL,
    modalType,
    modalProps
  });
};

export const clearModal = () => async dispatch => {
  unlockBackgroundForModal();
  dispatch({
    type: CLEAR_MODAL,
    modalType: undefined,
    modalProps: undefined
  });
};

export const addUserResponse = userResponse => async dispatch => {
  getResponseRef()
    .push()
    .set(userResponse);
};

export const editUserResponse = (id, response) => async dispatch => {
  getResponseRef()
    .child(id)
    .update(response);
};

export const removeUserResponse = userResponseId => async dispatch => {
  getResponseRef()
    .child(userResponseId)
    .remove();
};

export const fetchResponseData = () => async dispatch => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return;
  }

  getResponseRef()
    .once('value')
    .then(function(snapshot) {
      dispatch({
        type: FETCH_RESPONSE_DATA,
        responseData: snapshot.val()
      });
    });
};

const getResponseRef = () => {
  return firebase
    .database()
    .ref('/responses/' + firebase.auth().currentUser.uid);
};

export const addChild = child => async dispatch => {
  getUserRef()
    .push()
    .set(child);
};

export const editChild = (id, child) => async dispatch => {
  getUserRef()
    .child(id)
    .update(child);
};

export const removeChild = id => async dispatch => {
  getUserRef()
    .child(id)
    .remove();
};

export const fetchUserData = () => async dispatch => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return;
  }

  getUserRef()
    .once('value')
    .then(function(snapshot) {
      dispatch({
        type: FETCH_USER_DATA,
        userData: snapshot.val()
      });
    });
};

const getUserRef = () => {
  return firebase.database().ref('/users/' + firebase.auth().currentUser.uid);
};

export * from './auth';
